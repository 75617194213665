import React from "react";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const Loader = ({ customSize = 40, primaryColor, secondaryColor, suspense }) => (
    <Box
        sx={{
            position: "relative",
            display: "flex",
            width: suspense && "100%",
            height: suspense && "100vh",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <CircularProgress
            variant="determinate"
            sx={{
                color: primaryColor,
            }}
            size={customSize}
            thickness={4}
            value={100}
        />
        <CircularProgress
            disableShrink
            sx={{
                color: secondaryColor,
                animationDuration: "550ms",
                position: "absolute",

                [`& .${circularProgressClasses.circle}`]: {
                    strokeLinecap: "round",
                },
            }}
            size={customSize}
            thickness={4}
        />
    </Box>
);

Loader.propTypes = {
    customSize: PropTypes.number,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    suspense: PropTypes.bool,
};

Loader.defaultProps = {
    customSize: 40,
    primaryColor: "#DB6015",
    secondaryColor: "#fff",
    suspense: false,
};
export default Loader;
