// Display the toast as a flex of a fixed position to align its content
// and also itself to the center of the parent wrapper
export const toastStyles = (toggle) => ({
  position: "fixed",
  top: "0",
  left: "50%",
  display: "flex",
  alignItems: "center",
  // Place a margin atop of the toast
  marginTop: "50px !important",
  // Make the font a bit bolder and apply extra padding
  fontWeight: "bolder",
  padding: "20px",
  // Centralize the toast to the center of the page on the horizontal
  // axis
  transform: toggle ? "translate(-50%, 0%)" : "translate(-50%, -200%)",
  transition: "all 0.5s ease-in-out",
  opacity: 0.98,
  zIndex: 2147483647,
});
