import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getNews } from "services/news.service";
import i18next from "i18next";
import { Box, Typography } from "@mui/material";
import propsTranslation from "helpers/propsTranslation";
import dateFormating from "helpers/dateFormation";
// import dashSeperate from "helpers/dashSeperate";
import { Link } from "react-router-dom";
import * as styles from "./LastNews.style";
import { toast } from "../uikit/toast";

const LastNews = ({ limit, dateColor, titleColor }) => {
    const [newsData, setNews] = useState([]);
    //  const [isLoading, setIsLoading] = useState(false);
    const textAlign = i18next.language === "ar" ? "right" : "left";

    useEffect(() => {
        //   setIsLoading(true);
        getNews({ limit, page: 1, sort: "desc" })
            .then((res) => {
                setNews(res?.data);
            })
            .catch((err) => {
                toast.error(err.message);
            })
            .finally();
    }, []);
    const getSpecifiedLength = (text, count) => {
        let res = "";
        if (text.length > count) {
            res = `${text.substr(0, count)}...`;
        } else {
            res = text;
        }
        return res;
    };
    return (
        <Box sx={styles.wraper}>
            {newsData.map((news) => (
                <Link
                    to={`/news/${news?.id}`}
                    style={{ textDecoration: "none" }}
                >
                    <Box sx={styles.cardsWraper}>
                        <Box sx={styles.imgWraper}>
                            <img
                                src={news?.main_image?.url}
                                alt="latest news"
                                style={styles.img}
                            />
                        </Box>
                        <Box sx={styles.content}>
                            <Typography sx={styles.header(textAlign, titleColor)}>
                                {getSpecifiedLength(propsTranslation(news?.head_line), 35)}
                            </Typography>
                            <Typography sx={styles.date(textAlign, dateColor)}>
                                {dateFormating(news?.publish_date)}
                            </Typography>
                        </Box>
                    </Box>
                </Link>
            ))}
        </Box>
    );
};

LastNews.propTypes = {
    limit: PropTypes.number.isRequired,
    dateColor: PropTypes.string,
    titleColor: PropTypes.string,
};
export default LastNews;
