import { atom } from "recoil";
import { get } from "configs/localstorage.service";

const activeNavLink = atom({
    key: "activeNavLink",
    default: get("active") || "0",
});

const parentDropdownClose = atom({
    key: "dropdown",
    default: true,
});
const navbarOpened = atom({
    key: "navbarOpened",
    default: false,
});
const sectorsFilter = atom({
    key: "sectorsFilter",
    default: [],
});
const researchFilter = atom({
    key: "researchFilter",
    default: [],
});
const footerState = atom({
    default: false,
    key: "footerState",
});

const cartItems = atom({
    key: "cart",
    default: (get("cartItems") && [...JSON.parse(get("cartItems"))]) || [],
});
const staticContent = atom({
    key: "staticContent",
    default: null,
});

const mapMarkers = atom({
    key: "markers",
    default: [],
});
export {
    activeNavLink,
    footerState,
    parentDropdownClose,
    cartItems,
    sectorsFilter,
    researchFilter,
    mapMarkers,
    navbarOpened,
    staticContent,
};
