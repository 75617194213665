const Navbars = (opened) => ({
    position: { xs: opened ? "fixed" : "absolute", lg: "absolute" },
    top: 0,
    zIndex: 99,
    width: "100%",
    overflow: "hidden",
    opacity: "0.9",
    paddingInline: { xs: 2, sm: 1, md: 5 },
    transition: "ease-in-out .3s",
    background: "transparent",
});
const LogoStyles = (image) => ({
    backgroundImage: `url('${image}')`,
    width: 20,
    zIndex: 999999,
    height: 20,
    "@media screen and (max-width: 600px)": {
        background: "red",
    },
});
const NavItems = (black) => ({
    color: black ? "#000" : "#fff",
    textDecoration: "none",
    position: "relative",
    transition: "ease-in-out .2s",
    fontWeight: 600,
    fontSize: "1rem",
    userSelect: "none",
    "&:hover": {
        color: "#FDD73B",
    },
    "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#DB6015",
        height: 2,
        transform: "scaleX(0)",
        transition: "ease-in-out .2s",
    },
    "&:hover:after": {
        transform: "scaleX(60%)",
    },
});
const activeLink = (state) => ({
    color: state && "#FDD73B",
    "&::after": state && {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#DB6015",
        height: 2,
        transform: "scaleX(60%)",
    },
});

const BurgerMenu = (trans, opened, black) => ({
    background: black ? "#DB6015" : "#fff",
    transition: "ease-in-out .2s",
    width: 37,
    height: 4,
    marginTop: !opened && "4.5px",
    opacity: trans === 2 && opened && 0,
    transform: opened && trans,
    textDecoration: "none",
    borderRadius: 2,
});
const DropDown = (black) => ({
    color: black ? "#000" : "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    userSelect: "none",
    transition: "ease-in-out .2s",
    position: "relative",
    fontWeight: 600,
});

const DropIcon = (opened) => ({
    transition: "ease-in-out .2s",
    width: "12px !important",
    height: "12px !important",
    marginInline: 1,
    transform: opened && "rotate(180deg)",
    cursor: "pointer",
});
const HorizentalDropIcon = (black, dir) => ({
    transition: "ease-in-out .2s",
    transform: dir === "rtl" && !black && "rotate(180deg)",
});
const MenuList = () => ({
    overflow: "visible",
    zIndex: 99999,

    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
    },
    "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 10,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
    },
});
const LanguageNav = (responsive, black) => ({
    width: 30,
    height: 30,
    border: responsive || black ? "2px solid #DB6015" : "2px solid #fff",
    cursor: "pointer",
    transition: "ease-in-out .2s",
});
const ButtonStyles = (filled, responsive, black) => ({
    paddingInline: 3,
    color: (responsive || black) && !filled ? "#DB6015" : "#fff",
    backgroundColor: filled && "#DB6015",
    borderRadius: 5,
    border: !filled && !responsive && !black ? "2px solid #fff" : "2px solid #DB6015",
    cursor: "pointer",
    transition: "ease-in-out .2s",
    "&:hover": {
        backgroundColor: filled && "#DB6015",
    },
});

const ResponsiveNavItems = () => ({
    color: "#fff",
    textDecoration: "none",
    position: "relative",
    transition: "ease-in-out .2s",
    textAlign: "right",
    userSelect: "none",
    "&:hover": {
        color: "#FDD73B",
    },
    "&::after": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#DB6015",
        height: 2,
        transform: "scaleX(0)",
        transition: "ease-in-out .2s",
    },
    "&:hover:after": {
        transform: "scaleX(60%)",
    },
});

export {
    Navbars,
    NavItems,
    BurgerMenu,
    DropDown,
    HorizentalDropIcon,
    DropIcon,
    activeLink,
    MenuList,
    LanguageNav,
    ButtonStyles,
    ResponsiveNavItems,
    LogoStyles,
};
