/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Arrow from "assets/icons/ArrowDown.svg";
import BlackArrow from "assets/icons/blackArrowDown.svg";
import ArrowBlack from "assets/icons/ArrowDownBlack.svg";
import ArrowBlackAr from "assets/icons/blackArrowAr.svg";
import { useRecoilState } from "recoil";
import {
    DropIcon,
    HorizentalDropIcon,
    MenuList,
    activeLink,
} from "components/shared/navbar/navbar.styles";
import { parentDropdownClose } from "recoil/atoms";
import { Avatar } from "@mui/material";

const CustomDropDown = ({
    label,
    items,
    setActive,
    active,
    current,
    sx,
    blackArr,
    anchor,
    transform,
    isParent,
    blackNav,
    setOpened,
}) => {
    const [parentClose, setParentClose] = useRecoilState(parentDropdownClose);
    const [sectors, setSectorsOpened] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { t, i18n } = useTranslation();
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const setArrow = () => {
        switch (true) {
            case blackArr:
                return i18n.language === "ar" ? ArrowBlackAr : ArrowBlack;
            case blackNav:
                return BlackArrow;
            default:
                return Arrow;
        }
    };

    return (
        <React.Fragment>
            <Typography
                variant="body1"
                onClick={(e) => {
                    handleClick(e);
                    setSectorsOpened((prev) => !prev);
                    if (isParent) setParentClose(false);
                }}
                sx={[sx, activeLink(active === current)]}
            >
                {label}
                <Avatar
                    src={setArrow()}
                    sx={[
                        DropIcon(isParent ? !parentClose && sectors : sectors),
                        HorizentalDropIcon(blackArr, i18n.dir),
                    ]}
                    alt="El Masry Logo"
                />
            </Typography>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={isParent ? open && sectors && !parentClose : open && sectors}
                onClose={(e) => {
                    handleClose(e);
                    setSectorsOpened(false);
                    if (isParent) setParentClose(true);
                }}
                css={MenuList}
                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                PaperProps={{
                    elevation: 0,
                }}
                transformOrigin={{
                    horizontal: transform.horizontal,
                    vertical: transform.vertical,
                }}
                anchorOrigin={{
                    horizontal: anchor.horizontal,
                    vertical: anchor.vertical,
                }}
            >
                {items?.map((item) =>
                    item?.isDropdown ? (
                        <CustomDropDown
                            label={t(item.label)}
                            items={item.items}
                            blackArr
                            sx={{
                                marginBlock: 1,
                                paddingInline: 2,
                                userSelect: "none",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                            }}
                            setActive={setActive}
                            setOpened={setOpened}
                            current={current}
                            transform={{
                                horizontal: i18n.language === "ar" ? "right" : "left",
                                vertical: "top",
                            }}
                            anchor={{
                                horizontal: i18n.language === "ar" ? "left" : "right",
                                vertical: "bottom",
                            }}
                        />
                    ) : (
                        <MenuItem
                            onClick={() => {
                                navigate(item?.link);
                                setOpened((prev) => !prev);
                                setSectorsOpened(false);
                                setParentClose(true);
                                item?.onClickAction();
                            }}
                        >
                            {t(`${item?.label}`)}
                        </MenuItem>
                    ))}
            </Menu>
        </React.Fragment>
    );
};

CustomDropDown.propTypes = {
    items: PropTypes.arrayOf.isRequired,
    label: PropTypes.string.isRequired,
    setActive: PropTypes.func.isRequired,
    active: PropTypes.string.isRequired,
    current: PropTypes.string.isRequired,
    sx: PropTypes.arrayOf.isRequired,
    blackArr: PropTypes.bool.isRequired,
    blackNav: PropTypes.bool.isRequired,
    setOpened: PropTypes.func.isRequired,
    isParent: PropTypes.bool.isRequired,
    transform: PropTypes.shape({
        horizontal: PropTypes.string,
        vertical: PropTypes.string,
    }),
    anchor: PropTypes.shape({
        horizontal: PropTypes.string,
        vertical: PropTypes.string,
    }),
};
CustomDropDown.defaultProps = {
    transform: {
        horizontal: "right",
        vertical: "top",
    },
    anchor: {
        horizontal: "right",
        vertical: "top",
    },
};
export default CustomDropDown;
