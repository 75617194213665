import { logout } from "services/user.service";
import Inclusive from "assets/icons/inclusive.svg";
import { set } from "configs/localstorage.service";

export const SectorsMenu = [
    { label: "navbar.economic_empowerment", link: "/", isDropdown: false },
    { label: "navbar.Al-Gharemin", link: "/", isDropdown: false },
    { label: "navbar.Education", link: "/", isDropdown: false },
    { label: "navbar.Early_childhood", link: "/", isDropdown: false },
    { label: "navbar.health", link: "/", isDropdown: false },
    { label: "navbar.environment_protection", link: "/", isDropdown: false },
    { label: "navbar.people_with_disabilities", link: "/", isDropdown: false },
    { label: "navbar.cultural_activities", link: "/", isDropdown: false },
];
export const DonationsMenu = [
    {
        label: "navbar.methods",
        link: "/",
        isDropdown: true,
        items: [
            {
                label: "navbar.branch",
                link: "/donations/methods",
                isDropdown: false,
                onClickAction: () => set("scrollTo", "1"),
            },
            {
                label: "navbar.abroad",
                link: "/donations/methods",
                isDropdown: false,
                onClickAction: () => set("scrollTo", "2"),
            },
            {
                label: "navbar.e-donation",
                link: "/donations/methods",
                isDropdown: false,
                onClickAction: () => set("scrollTo", "1"),
            },
            {
                label: "navbar.order_delegate",
                link: "/donations/methods",
                isDropdown: false,
                onClickAction: () => set("scrollTo", "3"),
            },
        ],
    },
    {
        label: "navbar.scopes",
        link: "/",
        isDropdown: true,
        items: [
            { label: "navbar.causes", link: "/causes", isDropdown: false },
            {
                label: "navbar.development",
                link: "/sector/20/التمكين%20الاقتصادي",
                isDropdown: false,
            },
            {
                label: "navbar.Concerte",
                link: "/donations/inkinddonations",
                isDropdown: false,
            },
            {
                label: "navbar.products",
                link: "/donations/products",
                isDropdown: false,
            },
        ],
    },
];
export const WhoAreWeMenu = [
    {
        label: "navbar.about_us",
        link: "/aboutUs",
        isDropdown: false,
    },
    {
        label: "navbar.boardoftrustees",
        link: "/boardoftrustees",
        isDropdown: false,
    },
    {
        label: "navbar.wise",
        link: "/committeeOfTheWise",
        isDropdown: false,
    },
    {
        label: "navbar.our_parteners",
        link: "/ourpartners",
        isDropdown: false,
    },
];
export const NewsAndEventsMenu = [
    {
        label: "navbar.events",
        link: "/events",
        isDropdown: false,
    },
    {
        label: "navbar.news",
        link: "/news",
        isDropdown: false,
    },

    {
        label: "navbar.success_stories",
        link: "/stories",
        isDropdown: false,
    },
];

export const ProfileMenu = [
    {
        label: "navbar.profile",
        link: "/profile",
        isDropdown: false,
    },
    {
        label: "navbar.mydonations",
        link: "/",
        isDropdown: false,
    },
    {
        label: "navbar.logout",
        link: "#",
        isDropdown: false,
        onClickAction: () => logout(),
    },
];

export const governrates = [
    "alexandria",
    "ismailia",
    "aswan",
    "asyut",
    "luxor",
    "red_sea",
    "beheira",
    "beni_suef",
    "port_said",
    "south_sinai",
    "giza",
    "dakahlia",
    "damietta",
    "sohag",
    "suez",
    "sharqia",
    "north_sinai",
    "gharbia",
    "faiyum",
    "cairo",
    "qalyubia",
    "qena",
    "kafr_el_sheikh",
    "matruh",
    "monufia",
    "minya",
    "new_valley",
];

export const OurSectorsList = [
    { color: "#FEEB9D", title: "home.disabled", body: "", icon: Inclusive },
    { color: "#A0D3E3", title: "home.cultural", body: "", icon: Inclusive },
    { color: "#EDAF8A", title: "home.health", body: "", icon: Inclusive },
    { color: "#80D4C7", title: "home.environment", body: "", icon: Inclusive },
    { color: "#FEEB9D", title: "home.al_gharmien", body: "", icon: Inclusive },
    { color: "#A0D3E3", title: "home.early_childhood", body: "", icon: Inclusive },
    { color: "#EDAF8A", title: "home.economic_empowerment", body: "", icon: Inclusive },
    { color: "#80D4C7", title: "home.education", body: "", icon: Inclusive },
    { color: "#FEEB9D", title: "home.general", body: "", icon: Inclusive },
];
