import AxiosInstance from "configs/api.service";

export const getNews = ({ limit, page, sort }) =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(`/api/news?limit=${limit}&page=${page}&sortDirection=${sort}`)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });

export const getSingleNews = (id) =>
    new Promise((resolve, reject) => {
        AxiosInstance.get(`/api/news/${id}`)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
