import { hydrate, render } from "react-dom";
import React, { Suspense } from "react";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
// import worker from "mocks/browser";
import { ToastContainer } from "components/shared/uikit/toast/ToastContainer";
// import "swiper/swiper-bundle.min.css";
import Loader from "./components/shared/uikit/loaders/loader/Loader";
import "./index.css";
import App from "./App";
import "./configs/i18n";

// if (process.env.NODE_ENV) {
//     worker.start();
// }
const MyAPP = (
    <RecoilRoot>
        <ToastContainer>
            <BrowserRouter>
                <Suspense
                    fallback={
                        <Loader
                            customSize={60}
                            suspense
                        />
                    }
                >
                    <App />
                </Suspense>
            </BrowserRouter>
        </ToastContainer>
    </RecoilRoot>
);
const MyAPP2 = (
    <RecoilRoot>
        <ToastContainer>
            <BrowserRouter>
                <Suspense
                    fallback={
                        <Loader
                            customSize={60}
                            suspense
                        />
                    }
                >
                    <div style={{ display: "none" }}>
                        <App />
                    </div>
                </Suspense>
            </BrowserRouter>
        </ToastContainer>
    </RecoilRoot>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(MyAPP, rootElement);
} else {
    render(MyAPP2, rootElement);
}
