import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Toast } from "./Toast";

// Create an empty object for the toast to toggle it upon the
// user's actions
export let toast = {};

export const ToastContainer = ({ children, time }) => {
    // Declare the initial state of the component
    const [isToast, setToast] = useState({});

    // Declare an array of types that the user shall user in order to
    // display an error/success/warning/info msg
    const types = useMemo(() => ["success", "info", "warning", "error"], []);

    // Loop through the types and toggle the one that the user chooses
    // displaying the message the user desires
    for (const type of types) {
        toast = {
            ...toast,
            [type]: (message) => {
                setToast({ type, toggle: true, message });
            },
        };
    }

    // Fire all actions below whenever the component renders
    useEffect(() => {
        let timer;
        // Check if the toast is toggled or not so that we can turn it
        // off after the timeout ends
        if (isToast.toggle) {
            timer = setTimeout(() => {
                // Set the default timeout to 2500ms unless the user has a
                // different choice
                setToast({ ...isToast, toggle: false });
            }, time ?? 2500);
        }
        // Clear the timer when the component unmounts
        return () => {
            clearTimeout(timer);
        };
        // Re-call the functions above in case on of the dependencies change
    }, [isToast, time]);

    return (
        <React.Fragment>
            <Toast
                toggle={isToast.toggle}
                message={isToast.message}
                type={isToast.type}
            />
            {children}
        </React.Fragment>
    );
};

ToastContainer.propTypes = {
    children: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
};
